import {Compass, Globe, Mail, PlayCircle, Smartphone} from 'lucide-react';
import {forwardRef, useState} from 'react';
import {CtaSection, FeatureCard} from '~/ui/Cta';
import {PricingCard} from '~/ui/PricingCard';
import {ButtonLink} from '~/ui/button-link';
import {InView} from '~/ui/in-view';
import {Slider} from '~/ui/slider';
import {View} from '~/ui/view';
import {cn} from '~/utils';
import {PageFooter} from '../$lang._public/PageFooter';
import {useDictionary} from '../$lang/route';

export default function Index() {
  const {t} = useDictionary();
  const [teamsCount, setTeamsCount] = useState([3]);
  const count = teamsCount[0];
  const priceAsNumber = count <= 5 ? 0 : count >= 200 ? 999 : count <= 20 ? 9.99 : count * 0.5;
  const price =
    priceAsNumber !== 999
      ? priceAsNumber.toLocaleString('fr-FR', {
          style: 'currency',
          currency: 'EUR',
        })
      : t(l => l.contact_us);
  return (
    <>
      <View>
        <main className="flex-1">
          <CtaSection
            className="bg-primary/5"
            title={t(l => l.cta_title)}
            subtitle={t(l => l.cta_subtitle)}
            actions={
              <div className="flex flex-row mt-8 gap-4 py-4">
                <ButtonLink colored variant="secondary" icon={Compass} to="/signup">
                  {t(l => l.try)}
                </ButtonLink>
                <ButtonLink target="_blank" colored icon={PlayCircle} to="https://zcal.co/simon-boisset/questovery">
                  {t(l => l.ask_for_demo)}
                </ButtonLink>
              </div>
            }>
            <FeatureCard title={t(l => l.cta_feat_1_title)} description={t(l => l.cta_feat_1_subtitle)} Icon={Globe} />
            <FeatureCard
              title={t(l => l.cta_feat_2_title)}
              description={t(l => l.cta_feat_2_subtitle)}
              Icon={Compass}
            />
            <FeatureCard
              title={t(l => l.cta_feat_3_title)}
              description={t(l => l.cta_feat_3_subtitle)}
              Icon={Smartphone}
            />
          </CtaSection>
          <InView>
            <LateralScreenshots
              action={
                <ButtonLink colored icon={Compass} to="/signup">
                  {t(l => l.start)}
                </ButtonLink>
              }
              reverse
              src="https://questovery-files.s3.fr-par.scw.cloud/public/screen-step.png"
              title={t(l => l.cta_screen_1_title)}
              description={t(l => l.cta_screen_1_subtitle)}
            />
          </InView>
          <InView>
            <CtaSection
              className="bg-secondary/10"
              title={t(l => l.cta_price_title)}
              secondary
              actions={
                <div className="flex flex-row mt-8 gap-4 py-4">
                  <ButtonLink colored icon={Mail} to="mailto:contact@questovery.com">
                    {t(l => l.contact_us)}
                  </ButtonLink>
                  <ButtonLink colored variant="secondary" icon={Compass} to="/signup">
                    {t(l => l.start)}
                  </ButtonLink>
                </div>
              }
              subtitle={t(l => l.plan_questovery_description)}>
              <PricingCard
                disabledMonth={priceAsNumber === 999}
                title={t(l => l.plan_questovery)}
                price={price}
                description={`${t(l => l.teams_count_during_the_month)}: ${count}`}
                features={[
                  t(l => l.full_edition_software),
                  t(l => l.manage_steps_and_questions),
                  t(l => l.add_images_and_audio),
                  t(l => l.geolocate_steps),
                  t(l => l.support_with_chat_and_email),
                  t(l => l.unlimited_quests),
                  t(l => l.unlimited_teams),
                ]}>
                <Slider defaultValue={[0]} max={250} step={1} value={teamsCount} onValueChange={setTeamsCount} />
              </PricingCard>
            </CtaSection>
          </InView>
        </main>
      </View>
      <PageFooter />
    </>
  );
}

type LateralScreenshotsProps = {
  className?: string;
  title: string;
  description: string;
  src: string;
  reverse?: boolean;
  action?: React.ReactNode;
};

const LateralScreenshots = forwardRef<any, LateralScreenshotsProps>(
  ({className, title, description, src, reverse, action}, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          className,
          'flex gap-12 items-start p-12 group flex-col',
          reverse ? 'sm:flex-row-reverse' : 'sm:flex-row ',
        )}>
        <div className="flex flex-col gap-4 flex-1">
          <div className="flex flex-col items-start gap-2">
            <h3 className="text-2xl font-bold text-primary">{title}</h3>
            <p className="flex-1 mb-8">{description}</p>
            {action}
          </div>
        </div>
        <img
          src={src}
          alt={title}
          className={cn(
            'sm:w-1/2 w-full group-hover:translate-x-0 ease-in-out duration-300 group-hover:scale-105 transition-transform shadow-md rounded-md object-cover',
            reverse ? 'sm:-translate-x-1/4' : 'sm:translate-x-1/4',
          )}
        />
      </div>
    );
  },
);
