import {Check} from 'lucide-react';
import {useDictionary} from '~/routes/$lang/route';
import {cn} from '~/utils';

type PricingCardProps = {
  title: string;
  description: string;
  price?: string;
  features?: string[];
  topChipLabel?: string;
  link?: {url: string; label: string};
  discount?: string;
  children?: React.ReactNode;
  disabledMonth?: boolean;
};

export const PricingCard = ({
  description,
  price,
  title,
  topChipLabel,
  features,
  discount,
  children,
  disabledMonth,
}: PricingCardProps) => {
  const {t} = useDictionary();
  return (
    <div
      className={`relative max-w-md bg-white flex-1 flex items-stretch flex-col rounded-xl border-2 mt-6 sm:mt-0 ${
        topChipLabel ? 'mt-10' : ''
      }`}>
      {topChipLabel ? (
        <span className="w-32 absolute -top-5 left-0 right-0 mx-auto px-3 py-2 rounded-full border shadow-md bg-white text-center text-gray-700 text-sm font-semibold">
          {topChipLabel}
        </span>
      ) : (
        ''
      )}
      <div className="p-8 space-y-4 flex flex-col min-h-56">
        <span className="text-secondary text-xl font-medium">{title}</span>
        <div className="text-gray-500 text-3xl font-semibold">
          <span className={cn(!!discount && 'line-through text-gray-700 text-2xl font-medium')}>{price}</span>
          {!disabledMonth && (
            <span className="mx-2 text-sm italic text-gray-400">/{t(l => l.month.toLocaleLowerCase())}</span>
          )}
          {!!discount && <span>{discount}</span>}
        </div>
        <p className="flex-1 text-sm text-gray-400">{description}</p>
        {children}
        <span className="text-gray-400 text-sm italic ">({t(l => l.prices_in_euros_vat_excluded)})</span>
      </div>
      {features && (
        <ul className=" border-t p-8 space-y-3">
          <li className="pb-2 text-gray-500 font-medium">
            <p>{t(l => l.features)}</p>
          </li>
          {features.map((featureItem, idx) => (
            <li key={idx} className="flex flex-row items-center gap-4">
              <Check className="text-secondary w-4 h-4" />
              <span className="flex-1 text-left text-sm text-gray-500">{featureItem}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
